<template>
  <div class="page-video bg" style="flex-direction: column">
    <div class="class--con" style="display: flex;align-items: center; justify-content: center">
      <class-but @forward="forward"/>
      <video :src="className === '书法' ? 'http://img.dwhs.cn/BOSS/template/shjy_template_shufa.mp4' : 'http://img.dwhs.cn/BOSS/template/shjy_template.mp4'" controls controlsList="nodownload"></video>
    </div>
<!--    <div style="width: 100%; height: 100%; display: flex;align-items: center; justify-content: center">-->
<!--      <video src="http://img.dwhs.cn/BOSS/template/shjy_template.mp4" controls controlsList="nodownload"></video>-->
<!--    </div>-->
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut";
import service from "@/utils/axios";
export default {
  name: "PageVideo",
  components: {ClassBut},
  data() {
    return {
      className:null,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getApiClassSubject()
    },
    async getApiClassSubject(){
      let res = await service.post('/dwart/an_teaching/hbk/v1/classSubject',{
         classId: this.$route.query.classId,
       })
      this.className = res
    },
    forward(){
      this.$router.push({
        name: 'PageFirst',
        query: this.$route.query
      })
    },
  },
}
</script>

<style lang="less" scoped>
@import "reportClass.less";
.page-video {
  video{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  //:deep(.class--back){
  //  background-image: url("~@/assets/image/conference/btn_back.png");
  //  background-size: 100% 100%;
  //  width: 68px;
  //  height: 68px;
  //  position: absolute;
  //  top: 30px;
  //  left: 30px;
  //  cursor:pointer ;
  //  z-index: 9999;
  //}
  //:deep(.class--forward){
  //  background-image: url("~@/assets/image/conference/btn_forward.png");
  //  background-size: 100% 100%;
  //  width: 68px;
  //  height: 68px;
  //  position: absolute;
  //  top: 30px;
  //  right: 30px;
  //  cursor:pointer ;
  //  z-index: 9999;
  //}
}
</style>
